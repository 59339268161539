import { ActionContext } from "vuex";
import sequenceRepository from "@/repository/sequence";
import { responseErrorHandler } from "@/utils";
import { ISequence } from "@/schemas/ISequence";
import { ISetting } from "@/schemas/ISetting";
import { statusMessage } from "@/utils/httpUtils";
import clientCache from "@/utils/cacheUtils";
import { ISession } from "@/schemas/ISession";
import { SessionState } from "@/schemas/Enums";

const actions = {
  updateSequence: (
    context: ActionContext<any, any>,
    payload: { sequence: ISequence; next?: CallableFunction }
  ): void => {
    if (payload.sequence.id != null) {
      sequenceRepository
        .update(context.rootState.token, payload.sequence.id, payload.sequence)
        .then((updated: ISequence) => {
          if (updated) {
            const updatedSequenceIndex = context.state.sequences.findIndex(
              (sequence: ISequence) => sequence.id === updated.id
            );
            if (updatedSequenceIndex !== -1) {
              context.commit("UPDATE_SEQUENCE", {
                index: updatedSequenceIndex,
                sequence: updated,
              });
            }
            if (payload.sequence.settings?.length) {
              const settings: ISetting[] = payload.sequence.settings.filter(
                (setting) => Object.entries(setting).length !== 0
              );
              const settingToUpdate = [...settings].filter(
                (setting) => !!setting.id
              );
              if (settingToUpdate.length) {
                settingToUpdate.forEach((setting) =>
                  context.dispatch(
                    "setting/updateSetting",
                    { id: setting.id, setting: setting },
                    { root: true }
                  )
                );
              }
              const settingToCreate = settings.filter((setting) => !setting.id);
              if (settingToCreate.length) {
                settings?.map(
                  (setting) => (setting.sequence_id = payload.sequence.id)
                );
                context.dispatch("setting/createSettings", settingToCreate, {
                  root: true,
                });
              }
            }
            if (payload.next) payload.next(updated);
          }
        })
        .catch((error) => {
          if (error.message === statusMessage["400"]) {
            context.commit(
              "SET_SNACK",
              {
                displaySnack: true,
                snackText: "Ce code est déjà attribué",
                snackColor: "warning",
                snackTimeOut: 4000,
              },
              { root: true }
            );
          } else {
            responseErrorHandler(error, context);
          }
        });
    }
  },
  /**
   * Retrieves all sequences
   * @param context
   * @param payload
   */
  fetchSequences: (
    context: ActionContext<any, any>,
    payload: { callback?: CallableFunction; next?: CallableFunction }
  ): void => {
    sequenceRepository
      .getAll(context.rootState.token)
      .then((sequences) => {
        context.commit("SET_SEQUENCES", sequences);
        if (payload.callback) {
          payload.callback(true);
        }
        if (payload.next) {
          payload.next(sequences);
        }
      })
      .catch((error) => responseErrorHandler(error, context));
  },

  /**
   * Retrieves sequences by their status
   * @param context
   * @param payload
   */
  fetchSequencesByStatus: (
    context: ActionContext<any, any>,
    payload: { state: SessionState; callback?: CallableFunction }
  ): void => {
    sequenceRepository
      .getSequenceByStatus(context.rootState.token, payload.state)
      .then((sequences) => {
        if (payload.callback) {
          payload.callback(sequences);
        }
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  /**
   * Delete sequence identified by payload.sequenceId
   * @param {ActionContext<any, any>} context
   * @param {Record<string, any>} payload
   */
  deleteSequence: (
    context: ActionContext<any, any>,
    payload: { id: string; next?: CallableFunction }
  ): void => {
    sequenceRepository
      .delete(context.rootState.token, payload.id)
      .then((deleted: boolean) => {
        if (deleted) {
          const sequenceIndex = context.state.sequences.findIndex(
            (sequence: ISequence) => sequence.id === payload.id
          );
          if (sequenceIndex) {
            context.commit("REMOVE_SEQUENCE", sequenceIndex);
          }
        }
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  /**
   * Create a sequence
   * @param {ActionContext<any, any>} context
   * @param {Record<string, any>} payload
   */
  createSequence: (
    context: ActionContext<any, any>,
    payload: { sequence: ISequence }
  ): void => {
    sequenceRepository
      .create(context.rootState.token, payload.sequence)
      .then((createdSequence: ISequence) => {
        context.commit("ADD_SEQUENCE", createdSequence);
        let settings = payload.sequence.settings;
        if (settings?.length) {
          settings = settings?.filter(
            (setting) => Object.entries(setting).length !== 0
          );
          settings.map((setting: ISetting) => {
            setting.sequence_id = createdSequence.id;
            delete setting.tip_groups;
          });
          context.dispatch("setting/createSettings", settings, { root: true });
        }
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  fetchFullById: (
    context: ActionContext<any, any>,
    payload: { id: string; next?: CallableFunction }
  ): void => {
    sequenceRepository
      .getFullById(context.rootState.token, payload.id)
      .then((response) => {
        if (payload.next) {
          payload.next(response);
        }
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  requestToJoin: (
    context: ActionContext<any, any>,
    payload: {
      sequenceCode: string;
      clientCode: string;
      username: string;
      otp: string;
      email: string;
      next?: CallableFunction;
      registered: boolean;
      onError?: CallableFunction;
    }
  ): void => {
    sequenceRepository
      .requestToJoin(payload.sequenceCode, {
        code: payload.clientCode,
        username: payload.username,
        otp: payload.otp,
        email: payload.email,
        registered: payload.registered,
      })
      .then((queuePosition) => {
        if (payload.next) {
          payload.next(queuePosition);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.message === statusMessage["400"]) {
          console.log(error);
          context.commit(
            "SET_SNACK",
            {
              displaySnack: true,
              snackText:
                "Le code ne correspond pas a celui envoyé par email. Veuillez vérifier.",
              snackColor: "warning",
              snackTimeOut: 4000,
            },
            { root: true }
          );
        }
        if (error.message === statusMessage["403"]) {
          context.commit(
            "SET_SNACK",
            {
              displaySnack: true,
              snackText: "Le code a expiré.",
              snackColor: "warning",
              snackTimeOut: 4000,
            },
            { root: true }
          );
        } else {
          responseErrorHandler(error, context);
        }
      });
  },
  fetchByCode: (
    context: ActionContext<any, any>,
    payload: {
      code: string;
      next?: CallableFunction;
      onError?: CallableFunction;
    }
  ): void => {
    sequenceRepository
      .getByCode(context.rootState.token, payload.code)
      .then((response) => {
        if (payload.next) {
          payload.next(response);
        }
      })
      .catch((error) => {
        if (payload.onError) {
          payload.onError(error);
        } else {
          responseErrorHandler(error, context);
        }
      });
  },
  fetchFullByCode: (
    context: ActionContext<any, any>,
    payload: {
      code: string;
      next?: CallableFunction;
    }
  ): void => {
    sequenceRepository
      .getFullByCode(context.rootState.token, payload.code)
      .then((response) => {
        if (payload.next) {
          payload.next(response);
        }
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  getConnectionRequests: (
    context: ActionContext<any, any>,
    payload: {
      sequenceCode: string;
      next?: CallableFunction;
    }
  ): void => {
    sequenceRepository
      .getRequested(context.rootState.token, payload.sequenceCode)
      .then((requests) => {
        if (payload.next) {
          payload.next(requests);
        }
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  fetchSequenceSessions: (
    context: ActionContext<any, any>,
    payload: {
      sequenceId: string;
      next?: CallableFunction;
    }
  ): void => {
    sequenceRepository
      .getSequenceSessions(context.rootState.token, payload.sequenceId)
      .then((sessions: ISession[]) => {
        if (payload.next) payload.next(sessions);
      });
  },
  denyRequest: (
    context: ActionContext<any, any>,
    payload: {
      sequenceCode: string;
      denied: string;
      next?: CallableFunction;
    }
  ): void => {
    sequenceRepository
      .denyRequest(
        context.rootState.token,
        payload.sequenceCode,
        payload.denied
      )
      .then(() => {
        if (payload.next) {
          payload.next();
        }
      });
  },

  approveRequests: (
    context: ActionContext<any, any>,
    payload: {
      sequenceCode: string;
      next?: CallableFunction;
    }
  ): void => {
    sequenceRepository
      .approveRequests(context.rootState.token, payload.sequenceCode)
      .then((response) => {
        if (payload.next) {
          payload.next(response);
        }
      });
  },

  getApprovedUsers: (
    context: ActionContext<any, any>,
    payload: {
      sequenceCode: string;
      next?: CallableFunction;
    }
  ): void => {
    sequenceRepository
      .getApprovedUsers(context.rootState.token, payload.sequenceCode)
      .then((approved) => {
        if (payload.next) payload.next(approved);
      });
  },

  getRequestingUsers: (
    context: ActionContext<any, any>,
    payload: {
      sequenceCode: string;
      next?: CallableFunction;
    }
  ): void => {
    sequenceRepository
      .getRequestingUsers(payload.sequenceCode)
      .then((approved) => {
        if (payload.next) payload.next(approved);
        clientCache.cache("requestingUsers", approved)
      });
  },



  getOTP: (
    context: ActionContext<any, any>,
    payload: { email: string; code: string; next?: CallableFunction }
  ): void => {
    sequenceRepository
      .getOTP(payload.email, payload.code)
      .then(
        (response: { message?: string; username?: string; code?: string }) => {
          if (response.message) {
            console.log(response.message);
            //   TODO: message = 'unregistered' so cshould create username
          }
          if (response.code) {
            clientCache.cache("code", response.code);
          }
          if (response.username) {
            clientCache.cache("username", response.username);
          }
          if (payload.next) payload.next(response.username, response.code);
        }
      )
      .catch((error) => {
        if (error.message === statusMessage["422"]) {
          context.commit(
            "SET_SNACK",
            {
              displaySnack: true,
              snackText:
                "L'email est incorrect ou n'existe pas. Veuillez verifier",
              snackColor: "error",
              snackClosable: true,
            },
            { root: true }
          );
        }
        responseErrorHandler(error, context);
      });
  },

  getMySequences: (
    context: ActionContext<string, any>,
    payload: { next?: CallableFunction }
  ): void => {
    sequenceRepository
      .getMySequences(context.rootState.token)
      .then((sequences: ISequence[]) => {
        if (payload.next) payload.next(sequences);
      })
      .catch((error) => responseErrorHandler(error, context));
  },

  fetchSequenceGroups: (
    context: ActionContext<string, any>,
    payload: { id: string; next?: CallableFunction }
  ): void => {
    sequenceRepository
      .getSequenceGroups(context.rootState.token, payload.id)
      .then((groups: Record<string, any>[]) => {
        if (payload.next) payload.next(groups);
      })
      .catch((error) => responseErrorHandler(error, context));
  },

  fetchMyAuthId: (
    context: ActionContext<string, any>,
    payload: { email: string; sequenceCode: string; next?: CallableFunction }
  ) => {
    sequenceRepository
      .getMyQuickAuthId(payload.sequenceCode, payload.email)
      .then((message: Record<string, any>[]) => {
        if (payload.next) payload.next(message);
      })
      .catch((error) => responseErrorHandler(error, context));
  },

  getMyStatus: (
    context: ActionContext<string, any>,
    payload: {
      code: string;
      userCode: string;
      email: string;
      next?: CallableFunction;
    }
  ): void => {
    sequenceRepository
      .getMyStatus(payload.code, payload.userCode, payload.email)
      .then(
        (status: { status: string; sequence_id?: string; uuid?: string }) => {
          if (payload.next) payload.next(status);
        }
      )
      .catch((error) => responseErrorHandler(error, context));
  },

  checkOTP: (
    context: ActionContext<any, any>,
    payload: {
      sequenceCode: string;
      otp: string;
      email: string;
      next?: CallableFunction;
    }
  ): void => {
    sequenceRepository
      .checkOTP(payload.sequenceCode, {
        otp: payload.otp,
        email: payload.email,
      })
      .then((r) => {
        if (payload.next) payload.next(r);
      })
      .catch((error) => {
        if (error.message === statusMessage["400"]) {
          context.commit(
            "SET_SNACK",
            {
              displaySnack: true,
              snackText:
                "Vous n'avez pas été approuvé au préalable ou bien la séquence n'est pas encore ouverte.",
              snackColor: "yellow darken-4",
              snackClosable: true,
            },
            { root: true }
          );
        } else {
          responseErrorHandler(error, context);
        }
      });
  },
};
export default actions;
