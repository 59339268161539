import { Roles } from "@/schemas/Enums";
import { env, EnvEnum } from "@/env";

export const canEnterAdmin: Roles[] = [
  Roles.Researcher,
  Roles.Admin,
  Roles.Instructor,
];
export const canCreate: Roles[] =
  env === EnvEnum.Beta
    ? [
        Roles.Researcher,
        Roles.Admin,
        // Temporary for beta
        Roles.Instructor,
      ]
    : [Roles.Researcher, Roles.Admin];

export const canDuplicate: Roles[] = [
  Roles.Researcher,
  Roles.Admin,
  Roles.Instructor,
];
export const canDelete: Roles[] = [Roles.Researcher, Roles.Admin];
