import { apiUrl } from "@/env";
import { authHeader, corsHeaders } from "@/repository/deps";
import { BaseRepository } from "@/repository/base";
import { IUser } from "@/schemas/IUser";
import { Roles } from "@/schemas/Enums";

class UserRepository extends BaseRepository<IUser> {
  /**
   * Retrieves sessions attributed to user identified by userId
   * @param token
   * @param userId
   */
  getUserSessions(token: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/me/sessions`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getUserSequencesSessions(token: string, sequenceId: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/me/sessions/${sequenceId}`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getUserSequences(token: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/me/sequences`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  /**
   * Retrieve current user information
   * @param token
   */
  getCurrentUser(token: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/me`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  fetchPowered(token: string): Promise<any> {
    return fetch(
      `${apiUrl}/${this.basePath}?roles=${Roles.Instructor}_${Roles.Admin}_${Roles.Researcher}`,
      {
        method: "GET",
        headers: { ...authHeader(token), ...corsHeaders },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  fetchInstructors(token: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}?roles=${Roles.Instructor}`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
}
const userRepository = new UserRepository("users");

export default userRepository;
